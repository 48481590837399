/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
    var map = null;
    var markers = [];
    var $grid = null;
    var controller = new ScrollMagic.Controller();
    var ease = Expo.easeInOut;
    $.fn.replace_html = function (html) {
        return this.each(function () {
            var el = $(this);
            el.stop(true, true, false);
            el.css({ height: "auto", opacity: 0 });
            var cur = { height: el.outerHeight() + "px" };
            el.html(html);
            var finish = { height: el.outerHeight() + "px", opacity: 1 };
            el.css(cur).animate(finish, 750);
        });
    };

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        base_url: document.head.querySelector("[name=base_url]").content,
        common: {
            init: function () {
                $(document).foundation(); // Foundation JavaScript

                Sage.common.mobile_nav();
                Sage.common.touch_menu();
                Sage.common.grid();
                Sage.common.wow();
                Sage.common.blog();
                Sage.common.quote_slider();
                Sage.common.home_slider();
                Sage.common.event_callout();
                Sage.common.print();

                // remove shortcode brs
                $(".accordion > br").remove();
            },
            print: function () {
                $(".page-content").on("click", ".button--print", function (e) {
                    e.preventDefault();
                    window.print();
                });
            },
            event_callout: function () {
                if ($(".event-callout").length) {
                    var event = $(".event-callout").data("event-id");
                    if (
                        !Cookies.get("mif-event") ||
                        Cookies.get("mif-event") !== event
                    ) {
                        $(".event-callout").addClass("open");
                    }
                    $(".event-callout__close").on("click", function () {
                        Cookies.set("mif-event", event, { expires: 1 });
                        $(".event-callout").removeClass("open");
                    });
                }
            },
            quote_slider: function () {
                $(".quote-slider").each(function (index, element) {
                    var swiper = new Swiper($(".swiper-container", this)[0], {
                        speed: 400,
                        spaceBetween: 100,
                        loop: true,
                        autoHeight: false,
                        pagination: {
                            el: $(".swiper-pagination", this)[0],
                            clickable: true,
                        },
                    });
                });
            },
            home_slider: function () {
                console.log("hi");
                $(".home-slider").each(function (index, element) {
                    var swiper = new Swiper($(".swiper-container", this)[0], {
                        speed: 1000,
                        loop: true,
                        autoHeight: false,
                        pagination: false,
                        effect: "fade",
                        fadeEffect: {
                            crossFade: true,
                        },
                        autoplay: {
                            delay: 3000,
                        },
                    });
                });
            },
            blog: function () {
                $(".filter-posts__select").on("change", function () {
                    $(this).parents("form").trigger("submit");
                });
            },
            grid: function () {
                if ($(".grid").length) {
                    var grid = $(".grid").isotope({
                        itemSelector: ".grid-item",
                        percentPosition: true,
                        masonry: {
                            // use outer width of grid-sizer for columnWidth
                            columnWidth: ".grid-sizer",
                        },
                    });
                    $(window).on("load resize", function () {
                        grid.isotope("layout");
                    });
                    grid.imagesLoaded().progress(function () {
                        grid.isotope("layout");
                    });
                }
            },
            zoom: 15,
            new_map: function ($el) {
                var $markers = $(".marker");
                var args = {
                    zoom: Sage.common.zoom,
                    center: new google.maps.LatLng(0, 0),
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    styles: [
                        {
                            featureType: "administrative",
                            elementType: "all",
                            stylers: [
                                { lightness: "0" },
                                { gamma: "1.00" },
                                { visibility: "simplified" },
                            ],
                        },
                        {
                            featureType: "administrative",
                            elementType: "labels.text.fill",
                            stylers: [{ color: "#959595" }],
                        },
                        {
                            featureType: "landscape",
                            elementType: "all",
                            stylers: [{ color: "#f2f2f2" }],
                        },
                        {
                            featureType: "poi",
                            elementType: "all",
                            stylers: [{ visibility: "off" }],
                        },
                        {
                            featureType: "road",
                            elementType: "all",
                            stylers: [
                                { saturation: -100 },
                                { lightness: 45 },
                                { visibility: "simplified" },
                            ],
                        },
                        {
                            featureType: "road.highway",
                            elementType: "all",
                            stylers: [{ visibility: "simplified" }],
                        },
                        {
                            featureType: "road.highway",
                            elementType: "geometry.fill",
                            stylers: [{ color: "#ffffff" }],
                        },
                        {
                            featureType: "road.arterial",
                            elementType: "labels.icon",
                            stylers: [{ visibility: "off" }],
                        },
                        {
                            featureType: "transit",
                            elementType: "all",
                            stylers: [{ visibility: "off" }],
                        },
                        {
                            featureType: "water",
                            elementType: "all",
                            stylers: [
                                { color: "#dde6e8" },
                                { visibility: "on" },
                            ],
                        },
                    ],
                };
                map = new google.maps.Map($el[0], args);
                map.markers = [];
                Sage.common.add_marker($markers.first(), map);
                Sage.common.center_map(map);
                return map;
            },
            clear_marker: function (map) {
                for (var i = 0; i < map.markers.length; i++) {
                    map.markers[i].setMap(null);
                }
                map.markers = [];
            },
            add_marker: function ($marker, map) {
                var icon = {
                    path: "M36.7 18.4C36.7 8.2 28.5 0 18.4 0A18.42 18.42 0 0 0 3.5 29.2l14.8 25.7 16.5-28.3h-.1c1.3-2.5 2-5.3 2-8.2zM18.4 23c-2.9 0-5.2-2.3-5.2-5.2s2.3-5.2 5.2-5.2 5.2 2.3 5.2 5.2-2.4 5.2-5.2 5.2z",
                    fillColor: "#feda30",
                    fillOpacity: 1,
                    anchor: new google.maps.Point(18, 54),
                    strokeWeight: 0,
                    scale: 1,
                };
                var latlng = new google.maps.LatLng(
                    $marker.attr("data-lat"),
                    $marker.attr("data-lng")
                );
                var marker = new google.maps.Marker({
                    position: latlng,
                    icon: icon,
                    map: map,
                });
                map.markers.push(marker);
                if ($marker.html()) {
                    var infowindow = new google.maps.InfoWindow({
                        content: $marker.html(),
                    });
                    google.maps.event.addListener(marker, "click", function () {
                        infowindow.open(map, marker);
                    });
                }
            },
            center_map: function (map) {
                var bounds = new google.maps.LatLngBounds();
                $.each(map.markers, function (i, marker) {
                    var latlng = new google.maps.LatLng(
                        marker.position.lat(),
                        marker.position.lng()
                    );
                    bounds.extend(latlng);
                });
                if (map.markers.length === 1) {
                    map.setCenter(bounds.getCenter());
                    map.setZoom(Sage.common.zoom);
                } else {
                    map.fitBounds(bounds);
                }
            },
            mobile_nav: function () {
                $("button.hamburger").click(function () {
                    $("button.hamburger").toggleClass("active");
                    $("body").toggleClass("menu_open");
                    return false;
                });
                $(".mobile-nav .menu-item-has-children span").click(
                    function () {
                        $(this)
                            .parents(".menu-item-has-children")
                            .toggleClass("is-open");
                    }
                );
            },
            touch_menu: function () {
                $(
                    ".touch .nav-row .menu-primary-navigation-container .menu-item-has-children span"
                ).click(function () {
                    $(this).parent().toggleClass("is-open");
                });
                $(
                    ".no-touch .nav-row .menu-primary-navigation-container .menu-item-has-children"
                ).hover(
                    function () {
                        $(this).addClass("is-open");
                    },
                    function () {
                        $(this).removeClass("is-open");
                    }
                );
            },
            wow: function () {
                // set the controller for animations
                var duration = 1;

                // single wow - fadeIn & fadeInUp
                $(".wow").each(function (i) {
                    var tl = new TimelineMax();
                    var delay = $(this).data("delay") || 0;
                    var t1_duration = $(this).data("duration") || duration;

                    var animation = { ease: ease };

                    if ($(this).hasClass("fadeIn")) {
                        animation.ease = ease;
                        animation.autoAlpha = 0;
                        tl.from($(this), t1_duration, animation, delay);
                    }

                    if ($(this).hasClass("fadeInUp")) {
                        animation.ease = ease;
                        animation.autoAlpha = 0;
                        animation.y = 30;
                        tl.from($(this), t1_duration, animation, delay);
                    }

                    if ($(this).hasClass("fadeInSlideDown")) {
                        animation.autoAlpha = 0;
                        animation.y = "-100%";
                        tl.from($(this), t1_duration, animation, delay);
                    }

                    new ScrollMagic.Scene({
                        triggerElement: this,
                        triggerHook: 0.9,
                        reverse: false,
                    })
                        .setTween(tl) // trigger a TweenMax.to tween
                        .addTo(controller);
                });

                // series wow - fadeIn & fadeInUp
                $(".wows").each(function (i) {
                    var tl = new TimelineMax();
                    var delay = $(this).data("delay") || 0;
                    var t1_duration = $(this).data("duration") || duration;

                    var animation = { ease: ease };

                    if ($(".fadeIn", this)) {
                        animation.ease = ease;
                        animation.autoAlpha = 0;
                        tl.staggerFrom(
                            $(".fadeIn", this),
                            t1_duration,
                            animation,
                            0.05,
                            delay
                        );
                    }

                    if ($(".fadeInUp, > .menu-item", this)) {
                        animation.ease = ease;
                        animation.autoAlpha = 0;
                        tl.staggerFrom(
                            $(".fadeInUp, > .menu-item", this),
                            t1_duration,
                            animation,
                            0.05,
                            delay
                        );
                    }

                    new ScrollMagic.Scene({
                        triggerElement: this,
                        triggerHook: 0.9,
                        reverse: false,
                    })
                        .setTween(tl) // trigger a TweenMax.to tween
                        .addTo(controller);
                });
            },
        },
        blog: {
            init: function () {
                $(document).on(
                    "sf:ajaxfinish",
                    ".searchandfilter",
                    function () {
                        Foundation.reInit("equalizer");
                    }
                );
            },
        },
        our_members: {
            init: function () {
                var mySwiper = new Swiper(
                    ".featured-members .swiper-container",
                    {
                        speed: 400,
                        spaceBetween: 100,
                        loop: true,
                        autoHeight: true,
                        pagination: {
                            el: ".featured-members .swiper-pagination",
                            clickable: true,
                        },
                    }
                );
            },
        },
        contact: {
            init: function () {
                Sage.common.new_map($(".acf-map"));
            },
        },
        staff: {
            fixHeight: function () {
                var max_h = 0;
                $(".staff-member__overview").height("auto");
                $(".staff-member__overview").each(function () {
                    if ($(this).height() > max_h) {
                        max_h = $(this).height();
                    }
                });
                $(".staff-member__overview").height(max_h);
            },
            init: function () {
                Sage.staff.fixHeight();
                $(window).on("resize", Sage.staff.fixHeight);
            },
        },
        calendar: {
            resizeReady: false,
            init: function () {
                $("#calendar").fullCalendar({
                    columnHeaderText: function (mom) {
                        return mom.format("ddd").charAt(0);
                    },
                    header: {
                        left: "prev",
                        center: "title",
                        right: "next",
                    },
                    timeFormat: "h:mmt",
                    defaultView: "month",
                    height: "auto",
                    events: function (start, end, timezone, callback) {
                        $("#calendar .hasEvents").removeClass("hasEvents");
                        var data = {
                            action: "search_events",
                            start: start._d,
                            end: end._d,
                            types: $("#types").val() ? $("#types").val() : null,
                        };
                        $.ajax({
                            type: "POST",
                            url:
                                $("a.logo").attr("href") +
                                "/wp-admin/admin-ajax.php",
                            data: data,
                            success: function (data) {
                                // fix ampersand
                                data = data.replace(/&amp;/g, "&");
                                callback(JSON.parse(data));
                                Sage.calendar.resizeReady = true;
                            },
                        });
                    },
                    eventRender: function (event, element, view) {
                        var dateString = moment(event.start).format(
                            "YYYY-MM-DD"
                        );
                        $("#calendar")
                            .find('.fc-day[data-date="' + dateString + '"]')
                            .addClass("hasEvents");
                        $("#calendar")
                            .find('.fc-day-top[data-date="' + dateString + '"]')
                            .addClass("hasEvents");
                    },
                    eventAfterRender: function (event, element, view) {
                        if (event.host.name === "MIF Event") {
                            $(
                                '#calendar a[href$="' + event.url + '"]'
                            ).addClass("mif-event");
                        }
                    },
                });

                // $('#calendar').on('click', '.hasEvents', function () {
                //   var $modal = $('#calendarModal');
                //   $('.modal_content', $modal).html('Loading...');
                //   $modal.foundation('open');

                //   var data = {
                //     action: 'get_event',
                //     start: $(this).data('date'),
                //     types: ($('#types').val()) ? $('#types').val() : null
                //   };
                //   $.ajax({
                //     type: "POST",
                //     url: $('a.logo').attr('href') + "/wp-admin/admin-ajax.php",
                //     data: data,
                //     success: function (resp) {
                //       $('.modal_content', $modal).html(resp);
                //     }
                //   });
                //   return false;
                // });

                $("#month_switcher").on("change", function () {
                    var selected_date = moment($(this).val());
                    $("#calendar").fullCalendar("gotoDate", selected_date);
                });
                $("#types").on("change", function () {
                    $("#calendar").fullCalendar("refetchEvents");
                });
            },
        },
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = funcname === undefined ? "init" : funcname;
            fire = func !== "";
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === "function";

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire("common");

            // Fire page-specific init JS, and then finalize JS
            $.each(
                document.body.className.replace(/-/g, "_").split(/\s+/),
                function (i, classnm) {
                    UTIL.fire(classnm);
                    UTIL.fire(classnm, "finalize");
                }
            );

            // Fire common finalize JS
            UTIL.fire("common", "finalize");
        },
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
